<template>
  <div class="wrap-edit">
    <el-card>
    <FloatTitBtn>
    <div class="edit-header">
      <span>乡村建设规划许可证</span>
     <div>
       <el-button type="primary" @click="print">打印</el-button>
       <el-button type="primary" @click="onSubmit">保存</el-button>
     </div>
    </div>
    </FloatTitBtn>
    <div class="edit-container">
      <el-form
        :disabled="readOnly"
        ref="baseform"
        label-position="left"
        :model="form"
        label-width="120px"
      >
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="项目代码:">
              <el-input v-model="form.XMDM" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="文号:">
              <el-input v-model="form.WH"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="建设单位:">
              <el-input v-model="form.JSDW" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="项目名称:">
              <el-input v-model="form.XMMC" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="建设位置:">
              <el-input v-model="form.JSWZ" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="建设规模:">
              <el-input
                v-model="form.JSGM"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="附图附件名称:">
              <el-input
                v-model="form.FTMC"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发证日期:">
              <el-date-picker
                style="width: 100%"
                v-model="form.FZRQ"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    </el-card>
    <div v-show="false">
     <div id="form" >
       <div style="font-family: 'SimSun';font-size: 17px;color: black;height:100%;position:relative;">
         <div style="width:49%;display:inline-block;height:100%;">
           <div style="margin-top: 230px;margin-left:75%;font-size:16px;white-space: nowrap;">{{ form.WH }}&ensp;</div>
           <div style="margin-top: 390px;margin-left:74%;">慈溪市自然资源和规划局</div>
           <div style="margin-top: 40px;margin-left:75%;">{{ getDate(form.FZRQ)  }}&ensp;</div>
         </div>
         <div style="display:inline-block;width:300px;position:absolute;top:8%;left:76.5%;">
           <div style="height: 50px;">
             {{ form.JSDW }}&ensp;
           </div>
           <div style="height: 50px;">
             {{ form.XMMC }}&ensp;
           </div>
           <div style="height: 50px;">
             {{ form.JSWZ }}&ensp;
           </div>
           <div style="height: 50px;">
             {{ form.JSGM }} &ensp;
           </div>
         </div>
         <div style="position:absolute;top:40%;left:67%;">
             <div v-for="(v, i) in fjList" :key="i" style="font-size: 17px;width:400px;">
               <span style="line-height:10px;">{{ v }}</span>
             </div>
           </div>  
       </div>
     </div>
    </div>
  </div>
</template>

<script>
import mgr from "@/services/security.js";
import { ElMessage } from "element-plus";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
import print from "print-js";
import dayjs from "dayjs";
export default {
  name:'xcjsghxkz',
  components: { FloatTitBtn },
  data() {
    return {
      form: {
        Id: "",
        BJID: "",
        WH: "",
        XMDM: "",
        JSDW: "",
        XMMC: "",
        JSWZ: "",
        JSGM: "",
        FTMC: "",
        FZRQ: new Date(),
      },
      fjList:[],
      readOnly: true,
    };
  },
  methods: {
    print() {
      print({
        printable: "form",
        type: "html",
        scanStyles: false,
        css: "/static/landscapeA3.css",
        documentTitle: "",
      });
    },
    getDate(date) {
      var a = date
      a= dayjs(date).format('YYYY年MM月DD日')
      return a
    },
    async fetch(Id) {
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi + "/api/YW/Getxcjsghxkz?BJID=" + Id
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              this.form = response.data.Data;
              if(this.form.FTMC!=null && this.form.FTMC!=undefined){
                this.fjList = this.form.FTMC.split('\n')
              }
              // this.form.FZRQ=new Date()
              this.fetch2(Id)
            } else {
              console.log(response.data.ErrorMessage);
            }
          });
        const user = await mgr.getUser();
        if (this.form.JBR === user.profile.sub && this.$route.query.taskId) {
          this.readOnly = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
     async fetch2(Id) {
      try {
        await this.$ajax
            .get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/Getlahz?Id=" + Id)
            .then((response) => {
              if (response.data.IsSuccess) {
                const data = response.data.Data;
                this.form.XMDM = data.XMDM;
                this.form.JSDW = data.JSDW;
                this.form.XMMC = data.XMMC;
                this.form.JSWZ = data.JSWZ;
              } else {
                throw response.data.ErrorMessage;
              }
            });
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      let me = this;
      try {
        await this.$ajax
          .post(
            this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddOrUpdatexcjsghxkz",
            this.form
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.form.Id = response.data.Data;
              if(me.form.FTMC!=null && me.form.FTMC!=undefined){
                me.fjList = me.form.FTMC.split('\n')
              }
              ElMessage({
                type: "success",
                message: "保存成功!",
              });
            } else {
              ElMessage({
                type: "info",
                message: "保存失败!",
              });
              console.log(response.data.ErrorMessage);
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.form.BJID = this.$route.query.instanceId ?? "";
    this.fetch(this.form.BJID);
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-container{

}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}
</style>
